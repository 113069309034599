@import "css/variables.module.scss";

.container {
  margin: 0px auto;
  width: 100%;
  max-width: 800px;
  padding: 10px;
  box-sizing: border-box;
  line-height: 1.5;
}

@media (max-width: $mobile) {
  .container {
    padding: 10px;
    box-sizing: border-box;
  }
}

.section {
  padding-top: 20px;
  padding-bottom: 20px;
}
