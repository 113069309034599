@import "css/variables.module.scss";

.container {
  margin: 0px auto;
  width: 100%;
  max-width: 800px;
  padding: 10px;
  box-sizing: border-box;
  padding-bottom: 100px;
  margin-top: 20px;

  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
}

.text {
  text-align: left;
  line-height: 1.5;

  h3 {
    margin-top: 60px;
    margin-bottom: 40px;
  }
}

.endpoint {
  margin-top: 40px;
  margin-bottom: 40px;
}

.put {
  background: green;
  border-radius: 3px;
  padding: 5px 8px;
  margin-right: 5px;
  color: white;
  font-weight: 800;
}

.request {
  font-family: monospace;
  font-size: 16px;
  font-weight: 600;
}
