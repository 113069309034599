.container {
  background-color: #3f3f3f;
  color: white;
  font-family: Courier New, Courier, monospace;
  text-align: right;
  padding: 1rem;
}

.inputBox {
  width: 3em;
}
