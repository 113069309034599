.container {
  margin-top: 20px;
  max-width: 400px;
}

.header {
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 20px;
}

.image {
  display: block;
  max-width: 100%;
  min-width: 15px;

  //specify rendering so it doesn't blur out pixels
  image-rendering: pixelated;
  image-rendering: crisp-edges;
}
