@import "css/variables.module.scss";

.container {
  margin: 0px auto;
  width: 100%;
  max-width: 800px;
  padding: 10px;
  box-sizing: border-box;
  padding-bottom: 100px;
  margin-top: 20px;
  text-align: center;
}

.videoContainer {
  width: 100%;
  max-width: 500px;
  margin: 0px auto;
  margin-top: 40px;
}

.video {
  width: 100%;
}

.subtitlesContainer {
  margin-top: 40px;
  font-weight: 600;
  font-size: 18px;
}
