.container {
  margin-top: 20px;
  max-width: 400px;
}

.header {
  font-weight: 600;
  margin-bottom: 10px;
}

.image {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: contain;

  //center image relative to its bounding container
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.invisible {
  display: none;
}
