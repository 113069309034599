@import "css/variables.module.scss";

.container {
  margin: 0px auto;
  width: 100%;
  max-width: 800px;
  padding: 10px;
  box-sizing: border-box;
  padding-bottom: 100px;
}
